import "./App.css";
import Footer from "./components/Footer";
import Logo from "./images/logo.png";
import ImageCable from "./images/kabel.jpg";
import ImageCable1 from "./images/street5.jpeg";
import ImageCable2 from "./images/misson.jpg";
import ImageCable6 from "./images/vision1.jpg";
import Brenner from "./images/brennen.png";
import Partner from "./images/handschlag.png";
import Chat from "./images/diskussion.png";
import Schild from "./images/3279181.png";
import Videos from "./images/videos.png";
import FadingElement from "./components/FadingElement";
import Banner from "./images/street3.jpg";
// Icons
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import { useState } from "react";
import ContactForm from "./components/ContactForm";

function App() {
  return (
    <div className="wrapper">
      <div className="top">
        <img src={Logo} alt="logo" />
      </div>
      <header>
        <h3>Bauen für die Zukunft</h3>
        <h1>
          Ihr Partner für <br />
          <span style={{ color: "#b68e63" }}>Tiefbau und Infrastruktur</span>
        </h1>
        <h3>Kompetent und Zuverlässig</h3>
        <a href="#about">
          <ArrowDropDownRoundedIcon />
        </a>
      </header>

      <main id="about">
        <FadingElement>
          <h2 className="subtitle">Maßgeschneiderte Unterstützung durch</h2>
          <h1>Optimus GmbH</h1>
        </FadingElement>

        <FadingElement>
          <div className="textblock" id="ueberuns">
            <img src={ImageCable1} alt="arrow" />
            <div className="textarea">
              <h2>Über Optimus und unsere Leistungen</h2>
              <p>
                Wir sind Experten für Tiefbau und Infrastruktur: Von Glasfaser-
                und Stromnetzausbau bis hin zu individuellen Lösungen im
                Kanalbau - die Optimus GmbH steht für Qualität und
                maßgeschneiderte Projekte. Entdecken Sie mehr über unsere
                Leistungen und erfahren Sie, wie wir Ihre Vorhaben erfolgreich
                umsetzen.
              </p>
            </div>
          </div>
        </FadingElement>

        {/* <FadingElement>
          <div className="textblock reverse">
            <img src={ImageCable} alt="arrow" />
            <div className="textarea">
              <h2>Hier kommt ein blocktitel</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                nec odio. Praesent libero. Sed cursus ante dapibus diam. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
                odio. Praesent libero. Sed cursus ante dapibus diam.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Integer nec odio.
                Praesent libero. Sed cursus ante dapibus diam.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Integer nec odio.
                Praesent libero. Sed cursus ante dapibus diam.
              </p>
            </div>
          </div>
        </FadingElement>

        <FadingElement>
          <div className="textblock">
            <img src={ImageCable} alt="arrow" />
            <div className="textarea">
              <h2>Hier kommt ein blocktitel</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                nec odio. Praesent libero. Sed cursus ante dapibus diam. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
                odio. Praesent libero. Sed cursus ante dapibus diam.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Integer nec odio.
                Praesent libero. Sed cursus ante dapibus diam.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Integer nec odio.
                Praesent libero. Sed cursus ante dapibus diam.
              </p>
            </div>
          </div>
        </FadingElement> */}

        <div
          className="banner"
          style={{ marginBottom: "110px" }}
          id="leistungen"
        >
          <img src={Banner} alt="banner" />
          <div className="innertextblock">
            <span>UNSER FOKUS</span>
            <h2>Unsere Fachgebiete</h2>
            <ul>
              <li>Glasfaserausbau</li>
              <p
                style={{
                  color: "black",
                  fontSize: 13,
                  marginTop: 0,
                  paddingLeft: 20,
                }}
              >
                Moderne digitale Vernetzung durch zukunftsfähige
                Glasfasertechnologie.
              </p>
              <li>Stromnetzausbau</li>
              <p
                style={{
                  color: "black",
                  fontSize: 13,
                  marginTop: 0,
                  paddingLeft: 20,
                }}
              >
                Sichere und leistungsstarke Versorgung für Gemeinden und
                Unternehmen.
              </p>
              <li>Kanalbau und -anschluss</li>
              <p
                style={{
                  color: "black",
                  fontSize: 13,
                  marginTop: 0,
                  paddingLeft: 20,
                }}
              >
                Effiziente Lösungen für stabile und funktionale Abwassersysteme.
              </p>
              <li>Kanalbau und -anschluss</li>
              <p
                style={{
                  color: "black",
                  fontSize: 13,
                  marginTop: 0,
                  paddingLeft: 20,
                }}
              >
                Von der Planung bis zur Endabnahme - alles aus einer Hand.
              </p>
            </ul>
          </div>
        </div>

        <FadingElement>
          <div className="textblock reverse" id="mission">
            <img src={ImageCable2} alt="arrow" />
            <div className="textarea">
              <h2>UNSERE MISSION</h2>
              <p>
                Als zuverlässiger Partner bringen wir unsere Leidenschaft,
                Fachkompetenz und Verantwortung in jedes Projekt ein, um die
                Region nachhaltig zu stärken. Mit einem klaren Fokus auf
                Präzision und Nachhaltigkeit entwickeln wir maßgeschneiderte
                Lösungen, die den Anforderungen der Gegenwart gerecht werden und
                gleichzeitig den Herausforderungen der Zukunft standhalten.
                Unser Ziel ist es, eine Infrastruktur zu schaffen, die nicht nur
                heute funktioniert, sondern auch langfristig zukunftsfähig
                bleibt und den Menschen in der Region zugutekommt.
              </p>
            </div>
          </div>
        </FadingElement>

        <FadingElement>
          <div className="textblock" id="vision">
            <img src={ImageCable6} alt="arrow" />
            <div className="textarea">
              <h2>UNSERE VISION</h2>
              <p>
                Wir stärken Oberbayern mit innovativen Projekten, die von einem
                erfahrenen und engagierten Team mit Fachkompetenz und
                Verantwortung umgesetzt werden. Unser ganzheitlicher Ansatz
                verbindet Präzision und Nachhaltigkeit, um Lösungen zu schaffen,
                die sowohl ökologisch als auch ökonomisch zukunftsfähig sind.
                Wir setzen auf maßgeschneiderte Konzepte, die den regionalen
                Bedürfnissen gerecht werden und gleichzeitig eine nachhaltige
                Entwicklung fördern. Durch enge Zusammenarbeit mit lokalen
                Akteuren und einer vorausschauenden Planung tragen wir dazu bei,
                die Region langfristig zu stärken und eine lebenswerte Zukunft
                für kommende Generationen zu sichern.
              </p>
            </div>
          </div>
        </FadingElement>

        <FadingElement>
          <ContactForm />
        </FadingElement>

        {/* <div className="contactoptions">
          <a
            href="#"
            className="contactoption"
          >
            <InstagramIcon
              style={{ fontSize: 45, marginBottom: 15, color: "#ffffff" }}
            />
            <p>Instagram Dm</p>
          </a>
          <a href="mailto:#" className="contactoption">
            <EmailIcon
              style={{ fontSize: 45, marginBottom: 15, color: "#ffffff" }}
            />
            <p>Email</p>
          </a>
        </div> */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
